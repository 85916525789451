<template>
	<div>
		<Chart :cdata2="cdata2" />
	</div>
</template>

<script>
	import Chart from './line.vue';
	export default {
		props: {
			cdata2: {
				type: Object,
				default: () => {},
			}
		},
		data() {
			return {

			}
		},
		created() {

		},
		methods: {},
		components: {
			Chart,
		},
	}
</script>

<style lang="scss" scoped>
</style>
