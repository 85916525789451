<template>
	<div class="DataList">
		<search :form="form" :onSubmit="onSubmit" :at-present="present" type="ps">
			<template slot="ms">
				<dialog-mode :multiple-selection="multipleSelection" :binding-form="subControl" :get-data="getInfo"
					biaoshi="2"></dialog-mode>
			</template>
		</search>
		<div class="content">
			<el-table :data="tableData" style="width: 100%" :header-cell-style="{ background:'#043768',color:'#009AF4',border: '0'}" 
				@selection-change="handleSelectionChange">
			    <el-table-column type="selection" width="55"></el-table-column>
				<el-table-column label="负荷预测"  width="120" align="center">  
					<template slot-scope="scope">
						<el-button @click="handleClickData2(scope.row.id)" type="primary" size="small">查看</el-button>
					</template>
				</el-table-column>
				<el-table-column prop="ywGsyl" label="一网供水压力" width="120" align="center"></el-table-column>
			    <el-table-column prop="ywHsyl" label="一网回水压力" width="120" align="center"></el-table-column>
				<el-table-column prop="ywGswd" label="一网供水温度" width="120" align="center"></el-table-column>
				<el-table-column prop="ywHswd" label="一网回水温度" width="120" align="center"></el-table-column>
				<el-table-column prop="ywSsrl" label="一网瞬时热量" width="120" align="center"></el-table-column>
				<el-table-column prop="ywLjrl" label="一网累计热量" width="120" align="center"></el-table-column>
				<el-table-column prop="ywSsll" label="一网瞬时流量" width="120" align="center"></el-table-column>
				<el-table-column prop="ywLjll" label="一网累计流量" width="120" align="center"></el-table-column>
				<el-table-column prop="ewGsyl" label="二网供水压力" width="120" align="center"></el-table-column>
				<el-table-column prop="ewHsyl" label="二网回水压力" width="120" align="center"></el-table-column>
				<el-table-column prop="ewGswd" label="二网供水温度" width="120" align="center"></el-table-column>
				<el-table-column prop="ewHswd" label="二网回水温度" width="120" align="center"></el-table-column>
				<el-table-column prop="ewSsrl" label="二网瞬时热量" width="120" align="center"></el-table-column>
				<el-table-column prop="ewLjrl" label="二网累计热量" width="120" align="center"></el-table-column>
				<el-table-column prop="ewSsll" label="二网瞬时流量" width="120" align="center"></el-table-column>
				<el-table-column prop="ewLjll" label="二网累计流量" width="120" align="center"></el-table-column>
				<el-table-column prop="rlLj" label="热量累计点" width="120" align="center"></el-table-column>
				<el-table-column prop="bsllLj" label="补水流量累计点" width="140" align="center"></el-table-column>
				<el-table-column prop="dlLj" label="电量累计点" width="130" align="center"></el-table-column>
				<el-table-column prop="bsllLj" label="调节阀温度设定点" width="150" align="center"></el-table-column>
				<el-table-column prop="tjfWdsd" label="室外温度点" width="120" align="center"></el-table-column>
			</el-table>
			<div class="page">
				<el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange"
					:current-page="currentPage" :page-sizes="[10, 20, 50]" :page-size="pageSize" layout="total, sizes, prev, pager, next, jumper" :total="total">
				</el-pagination>
			</div>
		</div>
		<!--弹窗-->
		<el-dialog title="预测-2022年1月份" :visible.sync="dialogVisible" width="90%" :before-close="handleClose">
			<div class="chart" style="width: 100%;">
				<history-chart-two :cdata="cdata2" :lineId="111"></history-chart-two>
			</div>
			<span slot="footer" class="dialog-footer">
				<el-button @click="dialogVisible = false">关 闭</el-button>
			</span>
		</el-dialog>
	</div>
</template>

<script>
	// import { psList, psTemperature, psValve, submitHeatExchangeStation, tdeTemperature } from "@/assets/js/api";
	import { submitHeatExchangeStation, psTemperature, tableData, circleData } from "@/assets/js/api";
	import HistoryChartTwo from "../../components/echart/centerLeft/historyChartTwo";
	import { getForm } from "@/assets/js/api";
	export default {
		name: "list",
		components: {
			dialogMode: () => import("_c/dialogMode"),
			search: () => import("_c/search"),
			HistoryChartTwo
		},
		data() {
			return {
				cdata1:{},
				cdata2:{},
				subControl: submitHeatExchangeStation,
				present: psTemperature,
				multipleSelection: [],
				// control_type: {
				// 	'0': "回水温度全网平衡",
				// 	'1': "M手动控制开度",
				// 	'2': "T平台指定回温"
				// },
				// manufacturers: {
				// 	'1': "泰德尔",
				// 	'2': "普赛",
				// 	'3': "天罡"
				// },
				// change: 0,
				// activeName: 'all',
				dialogVisible: false,
				form: {
					valve: '在线',
					heating: '供暖',
					company: '',
					serial_number: '',
					village_name: '',
					area_name: '',
				},
				// list: [],
				currentPage: 1,
				pageSize: 10,
				total: 0,
				// access_token: '',
				//假数据
				tableData: [],
				id: ''
			}
		},
		watch:{
			"$route"(to,from){
				this.getOption();
			}
		},
		created() {
			this.getOption();
			// getToken().then(({data:{data}})=>{
			//   this.access_token = data.access_token
			//   localStorage.setItem('TOKEN', data.access_token);
			// })

			// this.list = infoData.RECORDS
			// this.total = infoData.RECORDS.length

			// setInterval(()=>{
			//   this.change++
			// }, 300000)
		},
		methods: {
			getOption(){
				this.id = this.$route.query.id
				this.getlist()
			},
			// 搜索
			getInfo() {
				let params = {
					pageNo: this.currentPage,
					pageSize: this.pageSize,
					fmzt: this.form.valve,
					gnzt: this.form.heating,
					fgs: this.form.company,
					fjbh: this.form.serial_number,
					hrz: this.form.village_name,
					xqmc: this.form.area_name
				}
				psList(params).then(({ data }) => {
					this.list = data.list
					this.total = data.count
				})
			},
			onSubmit() {
				this.getInfo()
			},
			// table
			handleSelectionChange(val) {
				this.multipleSelection = val;
			},
			// 分页
			handleSizeChange(val) {
				this.pageSize = val;
				this.getInfo();
			},
			handleCurrentChange(val) {
				this.currentPage = val;
				this.getInfo()
			},
			// 弹窗
			handleClickData2(itemId) {
				circleData({machineId: itemId}).then(({ data }) => {
					this.cdata2 = data
					this.dialogVisible = true
				})
			},
			handleClose(){
				this.dialogVisible = false
			},
			getlist (){
				tableData({stationId: this.id}).then(({ data }) => {
					this.tableData = data.list
				})
			},
		}
	}
</script>
<style scoped>
	@import url("../../assets/scss/content.css");
	/deep/ .el-table__body-wrapper::-webkit-scrollbar {
		width: 5px;
		height: 12px; 
		background-color: #051a3b;
	}
	/deep/ .el-table__body-wrapper::-webkit-scrollbar-thumb {
		background-color: #10408d;
		border-radius: 5px;
		height: 5px; 
	}
	/* .el-button{
		background: #009af4;
		border: 1px solid #009af4;
		color: white;
	} */
	.el-button--small{
		font-size: 13px;
	}
	/* 弹窗 */
	/deep/.el-dialog {
		background: #051A3B;
	}
	/deep/.el-dialog__title{
		color: #E5F6FF;
	}
	/deep/.el-radio__label{
		color: #E5F6FF;
	}
</style>
