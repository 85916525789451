<template>
	<div class="historyChart">
		<Echart :options="options" id="historyChart" height="480px" width="100%"></Echart>
	</div>
</template>

<script>
	import Echart from '@/common/echartAdmin';
	import {
		circleData
	} from "@/assets/js/api";
	export default {
		data() {
			return {
				options: {},
				switchStatusData: this.cdata,
			};
		},
		components: {
			Echart,
		},
		props: {
			cdata2: {
				type: Object,
				default: () => {},
			},
			lineId: {
				id: String,
				default: () => {},
			},
		},
		computed: {
			switchStatus: function() {
				return this.lineId // 直接监听props里的status状态
			}
		},
		watch: {
			cdata2: {
				handler() {
					let bgColor = "";
					let color = [
						"#0090FF",
						"#36CE9E",
						"#FFC005",
						"#FF515A",
						"#8B5CFF",
						"#00CA69"
					];

					let echartData = [
						{ name: "1",  value1: 30, value2: 91, value3: 90, value4: -19, value5:39.0, value6:33.7 },
						{ name: "2",  value1: 30, value2: 89, value3: 90, value4: -17, value5:39.5, value6:34.0 },
						{ name: "3",  value1: 33, value2: 93, value3: 93, value4: -20, value5:39.0, value6:33.9 },
						{ name: "4",  value1: 30, value2: 90, value3: 90, value4: -18, value5:38.0, value6:33.1 },
						{ name: "5",  value1: 30, value2: 90, value3: 90, value4: -18, value5:38.1, value6:33.2 },
						{ name: "6",  value1: 30, value2: 91, value3: 90, value4: -19, value5:38.3, value6:33.2 },
						{ name: "7",  value1: 27, value2: 85, value3: 85, value4: -9,  value5:38.1, value6:33.2 },
						{ name: "8",  value1: 33, value2: 93, value3: 93, value4: -20, value5:38.1, value6:33.1 },
						{ name: "9",  value1: 30, value2: 90, value3: 90, value4: -18, value5:39.0, value6:33.8 },
						{ name: "10", value1: 33, value2: 93, value3: 93, value4: -20, value5:40.1, value6:34.3 },
						{ name: "11", value1: 35, value2: 96, value3: 96, value4: -23, value5:38.4, value6:33.3 },
						{ name: "12", value1: 35, value2: 95, value3: 96, value4: -22, value5:38.3, value6:33.3 },
						{ name: "13", value1: 35, value2: 94, value3: 96, value4: -21, value5:38.8, value6:33.5 },
						{ name: "14", value1: 27, value2: 85, value3: 85, value4: -9,  value5:39.5, value6:34.0 },
						{ name: "15", value1: 30, value2: 90, value3: 90, value4: -18, value5:38.4, value6:33.3 },
						{ name: "16", value1: 30, value2: 89, value3: 90, value4: -17, value5:37.7, value6:32.8 },
						{ name: "17", value1: 33, value2: 93, value3: 93, value4: -20, value5:38.4, value6:33.2 },
						{ name: "18", value1: 35, value2: 95, value3: 96, value4: -22, value5:40.0, value6:34.3 },
						{ name: "19", value1: 40, value2: 100,value3: 100,value4: -24, value5:38.9, value6:33.2 },
						{ name: "20", value1: 40, value2: 97, value3: 100,value4: -23, value5:38.1, value6:32.8 },
						{ name: "21", value1: 37, value2: 85, value3: 85, value4: -9,  value5:38.8, value6:33.6 },
						{ name: "22", value1: 38, value2: 89, value3: 90, value4: -18, value5:39.9, value6:34.3 },
						{ name: "23", value1: 39, value2: 93, value3: 93, value4: -20, value5:40.0, value6:34.3 },
						{ name: "24", value1: 40, value2: 89, value3: 90, value4: -17, value5:38.0, value6:33.1 },
						{ name: "25", value1: 41, value2: 90, value3: 90, value4: -18, value5:37.7, value6:33.0 },
						{ name: "26", value1: 42, value2: 89, value3: 90, value4: -17, value5:37.4, value6:32.6 },
						{ name: "27", value1: 43, value2: 90, value3: 90, value4: -18, value5:38.3, value6:33.3 },
						{ name: "28", value1: 44, value2: 91, value3: 90, value4: -19, value5:37.7, value6:32.9 },
						{ name: "29", value1: 45, value2: 88, value3: 90, value4: -16, value5:37.3, value6:32.5 },
						{ name: "30", value1: 46, value2: 88, value3: 90, value4: -15, value5:39.1, value6:33.6 },
						// { name: "31", value1: 47, value2: 21, value3: 27, value4: 86, value5:99, value6:88},
					];
					let xAxisData = echartData.map(v => v.name);
					let yAxisData1 = echartData.map(v => v.value1);
					let yAxisData2 = echartData.map(v => v.value2);
					let yAxisData3 = echartData.map(v => v.value3);
					let yAxisData4 = echartData.map(v => v.value4);
					let yAxisData5 = echartData.map(v => v.value5);
					let yAxisData6 = echartData.map(v => v.value6);
					const hexToRgba = (hex, opacity) => {
						let rgbaColor = "";
						let reg = /^#[\da-f]{6}$/i;
						if (reg.test(hex)) {
							rgbaColor =
								`rgba(${parseInt("0x" + hex.slice(1, 3))},${parseInt( "0x" + hex.slice(3, 5))},${parseInt("0x" + hex.slice(5, 7))},${opacity})`;
						}
						return rgbaColor;
					}
					this.options = {
						backgroundColor: bgColor,
						color: color,
						legend: {
							right: 100,
							top: 10,
							textStyle: {
								color: 'rgb(255,255,255,0.9)',
								fontSize: 13,
							}
						},
						tooltip: {
							trigger: "axis",
							formatter: function(params) {
								let html = '';
								params.forEach(v => {
									if (v.seriesName=="室外最低气温"||v.seriesName=="二网供水温度"||v.seriesName=="二网回水温度") {
										v.unit = "℃"
									} else if (v.seriesName == "预测负荷") {
										v.unit = "瓦/平方米"
									} else if (v.seriesName == "预测阀门开度"||v.seriesName == "实际阀门开度") {
										v.unit = "%"
									}
									html +=
										`<div style="color: #666;font-size: 14px;line-height: 24px"><span style="display:inline-block;margin-right:5px;border-radius:10px;width:10px;height:10px;background-color:${color[v.componentIndex]};"></span>
							   ${v.seriesName}: <span style="color:${color[v.componentIndex]};font-weight:700;font-size: 18px">${v.value}</span> ${v.unit}`;
								})
								return html
							},
							extraCssText: 'background: white; border-radius: 0;box-shadow: 0 0 3px rgba(0, 0, 0, 0.2);color: #333;',
							axisPointer: {
								type: 'shadow',
								shadowStyle: {
									color: '#102951',
									shadowColor: 'rgba(225,225,225,1)',
									shadowBlur: 5
								}
							}
						},
						grid: {
							top: 90,
							containLabel: true
						},
						xAxis: [{
							type: "category",
							boundaryGap: false,
							axisLabel: {
								formatter: '{value}日',
								textStyle: {
									color: "white"
								}
							},
							axisLine: {
								lineStyle: {
									color: "white"
								}
							},
							data: xAxisData
						}],
						yAxis: [{
							type: "value",
							// name: '单位：(℃)/(w/平方米)/(%)',
							axisLabel: {
								textStyle: {
									color: "white"
								}
							},
							nameTextStyle: {
								color: "white",
								fontSize: 13,
								lineHeight: 40
							},
							splitLine: {
								lineStyle: {
									type: "dashed",
									color: "white"
								}
							},
							axisLine: {
								show: false
							},
							axisTick: {
								show: false
							}
						}],
						series: [{
							name: "预测负荷",
							type: "line",
							smooth: true,
							// showSymbol: false,/
							symbolSize: 8,
							zlevel: 3,
							lineStyle: {
								normal: {
									color: color[0],
									shadowBlur: 3,
									shadowColor: hexToRgba(color[0], 0.5),
									shadowOffsetY: 8
								}
							},
							data: yAxisData1
						}, {
							name: "预测阀门开度",
							type: "line",
							smooth: true,
							symbolSize: 8,
							zlevel: 3,
							lineStyle: {
								normal: {
									color: color[1],
									shadowBlur: 3,
									shadowColor: hexToRgba(color[1], 0.5),
									shadowOffsetY: 8
								}
							},
							data: yAxisData2
						}, {
							name: "实际阀门开度",
							type: "line",
							smooth: true,
							// showSymbol: false,
							symbolSize: 8,
							zlevel: 3,
							lineStyle: {
								normal: {
									color: color[2],
									shadowBlur: 3,
									shadowColor: hexToRgba(color[2], 0.5),
									shadowOffsetY: 8
								}
							},
							data: yAxisData3
						}, {
							name: "室外最低气温",
							type: "line",
							smooth: true,
							// showSymbol: false,
							symbolSize: 8,
							zlevel: 3,
							lineStyle: {
								normal: {
									color: color[3],
									shadowBlur: 3,
									shadowColor: hexToRgba(color[3], 0.5),
									shadowOffsetY: 8
								}
							},
							data: yAxisData4
						}, {
							name: "二网供水温度",
							type: "line",
							smooth: true,
							// showSymbol: false,
							symbolSize: 8,
							zlevel: 3,
							lineStyle: {
								normal: {
									color: color[4],
									shadowBlur: 3,
									shadowColor: hexToRgba(color[4], 0.5),
									shadowOffsetY: 8
								}
							},
							data: yAxisData5
						}, {
							name: "二网回水温度",
							type: "line",
							smooth: true,
							// showSymbol: false,
							symbolSize: 8,
							zlevel: 3,
							lineStyle: {
								normal: {
									color: color[5],
									shadowBlur: 3,
									shadowColor: hexToRgba(color[5], 0.5),
									shadowOffsetY: 8
								}
							},
							data: yAxisData6
						}]
					};
				},
				immediate: true,
				deep: true
			}
		},
		methods: {
			getData() {
				circleData({
					machineId: this.id
				}).then(({
					data
				}) => {
					console.log("data", data)
					// this.tableData = data.list
				})
			}
		}
	};
</script>
<style lang="scss" scoped>
	.historyChart {
		//background: rgb(244, 243, 245);
		padding: 10px;
		width: 100%;
		// float: left;
	}
</style>
